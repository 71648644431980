import {createAction} from '@reduxjs/toolkit'

const initialState = {
    miscData: {},
    isRequestCompleted: false,
    isLoading: false,
    isDetailLoading: false,
    isEdit: false,
    isError: false,
    isSuccess: false,
    rewardObject: {},
    cardObject: {},
    cardsList: []
}
export const editCard = createAction("cardReducer/editCard")
export const setCard = createAction("cardReducer/setCard")
export const setCards = createAction("cardReducer/setCards")
export const setLoading = createAction("cardReducer/setLoading")
export const setDetailLoading = createAction("cardReducer/setDetailLoading")
export const setIsEdit = createAction("cardReducer/setIsEdit")
export const setIsCardError = createAction("cardReducer/setIsCardError")
export const setIsCardSuccess = createAction("cardReducer/setIsCardSuccess")
export const setRequestCompleted = createAction("cardReducer/setRequestCompleted")

const cardReducer = (state = initialState, action) => {
    switch (action.type) {
        case setCards.type:
            return {
                ...state,
                cardsList: action.payload.data,
                miscData: action.payload.miscData,
                isLoading: false,
                isDetailLoading: false,
                isEdit: false,
                isError: false,
                isSuccess: false
            }
        case setCard.type:
            return {
                ...state,
                cardObject: action.payload.data,
                isEdit: false,
                isLoading: false,
                isDetailLoading: false
            }
        case editCard.type:
            return {
                ...state,
                cardObject: action.payload.data,
                isEdit: true
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setDetailLoading.type:
            return {
                ...state,
                isDetailLoading: action.payload
            }

        case setRequestCompleted.type:
            return {
                ...state,
                isRequestCompleted: action.payload
            }
        case setIsEdit.type:
            return {
                ...state,
                isEdit: action.payload.data
            }
        case setIsCardError.type:
            return {
                ...state,
                isError: action.payload
            }
        case setIsCardSuccess.type:
            return {
                ...state,
                isSuccess: action.payload
            }
        default:
            return state
    }
}

export default cardReducer