import React, { createContext, useState, useEffect } from 'react'

export const GlobalStateContext = createContext()

export const GlobalStateProvider = ({ children }) => {
    const [restaurantId, setRestaurantId] = useState(() => {
        // Get the initial state from localStorage
        const savedRestaurantId = localStorage.getItem('restaurantId')
        return savedRestaurantId ? JSON.parse(savedRestaurantId) : null
    })

    useEffect(() => {
        // Save restaurantId to localStorage whenever it changes
        if (restaurantId !== null) {
            localStorage.setItem('restaurantId', JSON.stringify(restaurantId))
        }
    }, [restaurantId])

    return (
        <GlobalStateContext.Provider value={{ restaurantId, setRestaurantId }}>
            {children}
        </GlobalStateContext.Provider>
    )
}
