import React from 'react'
import { LoadScript } from '@react-google-maps/api'

// eslint-disable-next-line no-unused-vars
const WithGoogleScript = (WrappedComponent) => {
    const apiKey = "AIzaSyD7O5Uv69qzHpX7OaZEfE5mla2FuJBXehQ" // Replace with your actual API key
    const libraries = ["places"]

    // This is a functional component that returns JSX.
    return (props) => (
        <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
            {/* Make sure WrappedComponent is invoked as JSX */}
            <WrappedComponent {...props} />
        </LoadScript>
    )
}

export default WithGoogleScript
