import {apiCall} from "../api/actions"
import {toast} from "react-toastify"
import httpService, {baseURL} from "../../utility/http"
import {push} from "react-router-redux"
import {
    handleLogin,
    handleLogout,
    setPasswordReset,
    setRequestCompleted,
    setTokenVerified,
    setTokenVerifiedFalse
} from "./authentication"
import {getHomeRouteForLoggedInUser} from "../../auth/utils"
import {Roles} from "../../utility/Roles"
import {setGroupOrder, setGroupOrderMeals} from "../../utility/Utils"
import {setGroupOrderExist} from "../customer/reducer"
import {store} from "../store"
import {calculateTotalItems} from "../cartItems/actions"

const url = 'auth/'

export const OTP_REQUEST_SUCCESS = 'OTP_REQUEST_SUCCESS'
export const OTP_REQUEST_FAIL = 'OTP_REQUEST_FAIL'
export const OTP_SUBMIT_SUCCESS = 'OTP_SUBMIT_SUCCESS'
export const OTP_SUBMIT_FAIL = 'OTP_SUBMIT_FAIL'


export const login = (username, password, isDeviceLoginEnabled, history, returnURL = null) => {

    return (dispatch) => {
        httpService
            ._post(`${baseURL}${url}login`, { username, password, isDeviceLoginEnabled })
            .then(res => {
                dispatch(setRequestCompleted(true))
                if (res.status === 200 && res.data.statusCode === 200) {
                    const {data} = res.data
                    const finalData = { ...data.userData, accessToken: data.accessToken}
                    dispatch(handleLogin(finalData))
                    const {userData: user} = data
                        const url = returnURL ? returnURL : getHomeRouteForLoggedInUser(user.role)
                        history.replace(url)

                    // check for already existing group order of a customer
                    if (user.role === Roles.customer) {
                        httpService._get(`${baseURL}groupOrder/getActive/${user.customerId}`).then(res => {
                                if (res && res.status === 200 && res.data && res.data.data && res.data.statusCode === 200) {
                                    const {data} = res.data
                                    setGroupOrderMeals(data)
                                    setGroupOrder(true, res.data.id)
                                    store.dispatch(calculateTotalItems(data.meals ? data.meals.length : null))
                                    store.dispatch(setGroupOrderExist(true))
                                }
                            }).catch(e => {
                                toast.error(e.message)
                            })
                    } else toast.success('Logged in Successfully')
                } else {
                    toast.error(res.data.message)
                }
            })
            .catch((err) => {
                dispatch(setRequestCompleted(true))
                toast.error(err.message)
            })
    }

}

export const register = (customer, history, setShowSignUpModal) => {
    return dispatch => {
        httpService
            ._post(`${baseURL}${url}register`, {...customer, passwordHash: customer.password, permission: ""})
            .then(res => {
                console.log('resSignUp', res)
                if (res.status === 200 && res.data.statusCode === 200) {
                    toast.success('Registered Successfully')
                    // Before calling setShowSignUpModal, check if it's a function
                    if (typeof setShowSignUpModal === 'function') {
                        setShowSignUpModal(false)
                        // ... (rest of the success logic)
                    } else {
                        console.error('setShowSignUpModal is not a function')
                    }
                    // Extract the accessToken and userData
                    const { accessToken, userData } = res.data.data

                    // Store accessToken and userData in local storage
                    localStorage.setItem('accessToken', accessToken)
                    localStorage.setItem('userData', JSON.stringify(userData))

                    // Optionally update Redux state
                    // Replace 'USER_LOGGED_IN' with your actual login action type
                    dispatch({ type: 'USER_LOGGED_IN', payload: userData })

                    const lastVisitedPage = sessionStorage.getItem('lastPage')
                    if (lastVisitedPage) {
                        history.push(lastVisitedPage)
                    } else {
                        // If there's no last visited page, redirect to a default page
                        history.push('/') // Replace 'defaultPage' with your default page route
                    }
                } else {
                    toast.error(res.data.message)
                }
            })
            .catch(err => {
                console.log('error', err)
                toast.error(err.message)
            })
    }
}

export const forgetPassword = (email) => {
    return (dispatch) => {
        const payload = {
            data: null,
            method: "get",
            isSuccessToast: true,
            requestCompleted: setRequestCompleted.type,
            url: `${url}PasswordReset?email=${email}`
        }
        dispatch(apiCall(payload))
    }
}
export const verifyToken = (queryString) => {
    return (dispatch) => {
        const payload = {
            data: null,
            method: "get",
            onSuccess: setTokenVerified.type,
            onError: setTokenVerified.type,
            url: `${url}VerifyToken${queryString}`
        }
        dispatch(apiCall(payload))
    }
}
export const resetNewPassword = (token, email, password) => {
    return (dispatch) => {
        const payload = {
            data: {token, email, password},
            method: "patch",
            isSuccessToast: true,
            requestCompleted: setRequestCompleted.type,
            onSuccess: setPasswordReset.type,
            url: `${url}PasswordReset`
        }
        dispatch(apiCall(payload))
    }
}
export const verifyEmail = (queryString) => {
    return (dispatch) => {
        const payload = {
            data: null,
            method: "get",
            isSuccess: setTokenVerified.type,
            onError: setTokenVerifiedFalse.type,
            url: `${url}ConfirmEmail${queryString}`
        }
        dispatch(apiCall(payload))
    }
}
export const resendEmail = (email) => {
    return (dispatch) => {
        const payload = {
            data: null,
            method: "get",
            isSuccessToast: true,
            successMessage: 'Email sent successfully',
            requestCompleted: setRequestCompleted.type,
            url: `${url}ResendEmail?email=${email}`
        }
        dispatch(apiCall(payload))
    }
}
export const unAuthorize = () => {
    localStorage.removeItem("isMember")
    return dispatch => {
        dispatch(handleLogout())
        dispatch(push('/login'))
        location.reload()  // (temporary) until find a solution to update route using redux
    }

}
export const requestOtpAction = (contactInfo) => {

    return apiCall({
        data: {
            userName: contactInfo,
            otp: '',
            requestType: 2
        },
        method: 'post',
        url: '/Auth/UserSignIn',
        onSuccess: OTP_REQUEST_SUCCESS,
        onError: OTP_REQUEST_FAIL
    })
}

export const submitOtpAction = (contactInfo, otp) => {

    return apiCall({
        data: {
            userName: contactInfo,
            otp,
            requestType: 1
        },
        method: 'post',
        url: '/Auth/UserSignIn',
        onSuccess: OTP_SUBMIT_SUCCESS,
        onError: OTP_SUBMIT_FAIL
    })
}

