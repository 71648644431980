import {createSlice} from "@reduxjs/toolkit"
/*import {cartTotalItems} from "../../utility/Utils"*/

const slice = createSlice({
    name: "cartItems",
    initialState: {
        meals:[],
        wines: [],
        total: 0/*cartTotalItems()*/,
        shippingAddress: null,
        billingAddress: null,
        quoteObject: {}
        //createDeliveryObject: {}
    },
    reducers: {
        addMeal: (cart, action) => {
            cart.meals.push({
                action
            })
        },
        totalCartItems: (cart, action) => {
            cart.total = action.payload

        },
        addShippingAddress: (cart, action) => {
            cart.shippingAddress = action
        },
        addBillingAddress: (cart, action) => {
            cart.billingAddress = action
        },
        quoteData: (state, action) => {
                state.quoteObject = action.payload
        }
        /*deliveryAction: (state, action) => {
            console.log('deliveryActionPayload', action)
            state.createDeliveryObject = action.payload
        }*/
    }
})

export const {addMeal, addShippingAddress, addBillingAddress, totalCartItems, quoteData} = slice.actions
export default slice.reducer
